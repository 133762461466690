import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import useStore from '../../data/store';
import { Project404 } from './404s';

const withProject = WrappedComponent => {
  const WithProject = ({ match, ...props }) => {
    const { projectStore } = useStore();
    const history = useHistory();
    useEffect(() => {
      if (projectStore.activeProjectId !== match?.params?.id) {
        projectStore.updateActiveProject(match?.params?.id);
        history.push(`/p/${projectStore.activeProjectId}/`);
      }
    }, [projectStore.activeProjectId]);
    if (isEmpty(projectStore.activeProject)) return <Project404 />;

    if (projectStore.activeProjectId !== match?.params?.id) return null;
    if (
      projectStore.activeProject.shouldBeInWaitingRoom &&
      !isEqual(match.path, '/p/:id/waiting')
    ) {
      return <Redirect to={`/p/${projectStore.activeProject.id}/waiting`} />;
    }

    if (
      isEqual(projectStore.activeProject.status, 'pending') &&
      !isEqual(match.path, '/p/:id/settings')
    ) {
      return <Redirect to={`/p/${projectStore.activeProject.id}/settings`} />;
    }

    if (
      projectStore.activeProject.isUpdatePending &&
      !match.path.startsWith('/p/:id/announcements')
    ) {
      return (
        <Redirect to={`/p/${projectStore.activeProject.id}/announcements`} />
      );
    }

    return <WrappedComponent match={match} {...props} />;
  };

  WithProject.displayName = `withProject(${WrappedComponent.displayName ||
    WrappedComponent.name})`;

  WithProject.propTypes = {
    match: PropTypes.instanceOf(Object).isRequired,
  };

  return WithProject;
};

export default withProject;
