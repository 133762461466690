import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import OtpInput from 'react-otp-input';
import { isEmpty } from 'lodash';
import useStore from '../../data/store';
import t from '../../t';
import WrappedHTML from '../shared/_wrapped_html';
import User from '../../data/entities/user';
import HubForm from '../hubs/hub_form';

function StartHub() {
  const { userStore } = useStore();
  const user = userStore.newUser;
  const [otp, setOtp] = useState('');

  useEffect(() => {
    if (!userStore.userSignedIn && isEmpty(userStore.newUser)) {
      userStore.update({
        newUser: new User({}, userStore),
      });
    }
  }, []);

  const handleUserCreate = e => {
    e.preventDefault();
    user.create();
  };

  const handleVerification = e => {
    e.preventDefault();
    user.verifyOTP(otp);
  };

  const renderFormBody = () => {
    if (userStore.userSignedIn) {
      return <HubForm />;
    }
    if (!isEmpty(userStore.newUser)) {
      const handleChange = e => {
        user.update({
          [e.target.name]: e.target.value,
        });
      };

      if (user.persisted)
        return (
          <form onSubmit={handleVerification}>
            <p>
              A Verification Code has been Sent to Your Email. To complete the
              registration process, please enter the verification code below
            </p>
            <FormGroup>
              <Label>Verification Code</Label>
              <div className="otp-input">
                <OtpInput
                  value={otp}
                  onChange={o => {
                    setOtp(o);
                    if (o.length === 6) {
                      user.verifyOTP(o);
                    }
                  }}
                  numInputs={6}
                  renderSeparator={<span className="otp-separator" />}
                  renderInput={props => <input {...props} />}
                />
              </div>
            </FormGroup>
            <Button color="primary" onClick={handleVerification}>
              Complete Verification
            </Button>
          </form>
        );

      return (
        <form onSubmit={handleUserCreate}>
          <p>
            To proceed with account creation, please provide the following
            required information.
          </p>
          <FormGroup>
            <Label>First Name</Label>
            <Input
              value={user.firstName}
              onChange={handleChange}
              name="firstName"
            />
          </FormGroup>
          <FormGroup>
            <Label>Last Name</Label>
            <Input
              value={user.lastName}
              onChange={handleChange}
              name="lastName"
            />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input value={user.email} onChange={handleChange} name="email" />
          </FormGroup>
          <Button color="primary" onClick={handleUserCreate}>
            Create User
          </Button>
        </form>
      );
    }

    return null;
  };

  return (
    <Row className="vertically-center">
      <Col lg={6}>
        <div className="text-left my-5">
          <h2>{t(`onboard.heading.hub_only`)}</h2>
          <WrappedHTML
            className="h5 mt-3"
            content={t(`onboard.subheading.hub_only`)}
          />
        </div>
      </Col>
      <Col lg={6}>
        <Card>
          <CardBody>{renderFormBody()}</CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default observer(StartHub);
