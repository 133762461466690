import React from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import { Redirect, useHistory } from 'react-router-dom';
import { toJS } from 'mobx';
import { isEmpty } from 'lodash';
import useStore from '../../data/store';
import { creatableProject } from '../layouts/_header';
import AccountForm from '../hubs/hub_form';
import t from '../../t';
import WrappedHTML from '../shared/_wrapped_html';
import HintedLabel from '../shared/_hinted_label';

function Onboard() {
  const { appStore, projectStore } = useStore();
  const { account } = projectStore;

  const creatableAccount = {
    name: 'account',
    component: AccountForm,
    modalTitle: t(`onboard.hub.labels.button`),
    size: 'md',
  };

  if (
    !account.hasAccess &&
    account.hasAccessProtected &&
    !appStore.accessCodeVerified
  ) {
    return (
      <Redirect
        to={{
          pathname: '/check-access-code',
          state: {
            redirectUrl: `/onboard`,
          },
        }}
      />
    );
  }
  const accountProxy = toJS(account);
  if (isEmpty(accountProxy)) {
    return null;
  }

  function helpableCard(key, createable, minFunding) {
    return (
      <Card className="onboard-card p-3">
        <div className="flex-grow-1">
          <CardHeader>{t(`onboard.${key}.labels.title`)}</CardHeader>
          <CardBody>
            <WrappedHTML content={t(`onboard.${key}.descriptions.lead`)} />
            <WrappedHTML
              className="mt-3"
              content={t(`onboard.${key}.descriptions.secondary`)}
            />
            <div className="text-left mt-4">
              <HintedLabel
                className="medium p-0 font-weight-bolder"
                helpTextKey={`create_${key}`}
                title={t(`onboard.${key}.hint_label`)}
                button
                video
              />
            </div>
          </CardBody>
        </div>
        <CardFooter className="py-3">
          <p>
            <strong>
              {t(`onboard.${key}.tokens`, {
                tokens: minFunding,
              })}
            </strong>
          </p>
          <Button
            color="primary"
            size="lg"
            className="btn-block btn-primary medium onboard-project-button"
            onClick={() => {
              if (key === 'helper') {
                window.location.href = createable;
              } else appStore.initiateCreatable(createable);
            }}
          >
            {t(`onboard.${key}.labels.button`)}
          </Button>
        </CardFooter>
      </Card>
    );
  }

  function projectCard() {
    return helpableCard(
      'project',
      creatableProject,
      accountProxy.settings.free_project_tokens
    );
  }

  function hubHelperCard() {
    return helpableCard('helper', accountProxy.defaultHubShare, '');
  }

  function hubCard() {
    return helpableCard(
      'hub',
      creatableAccount,
      appStore.minOrganisationFunding
    );
  }

  if (account.hasAccessPrivate && !account.hasAccess) {
    return (
      <div>
        <Card>
          <CardBody className="text-center">
            <p>This Hub is Private. You can only join by invitation.</p>
          </CardBody>
        </Card>
      </div>
    );
  }
  const heading_key = 'default';
  //  do not come here if already onboarded
  return (
    <div>
      <Row>
        <Col lg={12} className="mb-4">
          <div className="text-left my-5">
            <h2>{t(`onboard.heading.${heading_key}`)}</h2>
            <WrappedHTML
              className="h5 mt-3"
              content={t(`onboard.subheading.${heading_key}`)}
            />
          </div>
          <>
            {account.isEcosystem && hubCard()}
            {projectCard()}
            {!account.isEcosystem && hubHelperCard()}
          </>
        </Col>
      </Row>
    </div>
  );
}

export default observer(Onboard);
