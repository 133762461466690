import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { isEmpty, map } from 'lodash';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import useStore from '../../data/store';
import Spacer from '../layouts/spacer';
import t from '../../t';
import Player from '../modules/player';

function Discover() {
  const { discoverHubStore, projectStore } = useStore();

  useEffect(() => {
    if (projectStore.account.isEcosystem) {
      if (!discoverHubStore.fetching || !discoverHubStore.fetched) {
        discoverHubStore.fetch();
      }
    }
  }, [projectStore.account.isEcosystem]);

  if (isEmpty(projectStore.account)) return null;

  return (
    <div>
      <Spacer space={1} />
      <div className="centered w-90 hub-landing-page">
        <div className="p-1 text-center">
          <h4>Unlock Global Insights</h4>
          <h1>Discover Hubs</h1>
          <Spacer space={1} />
          <h2 className="font-weight-light">
            Seek inspiration, guidance, and support from the collective
            knowledge of our community. Discover Hubs is where innovators like
            you go to find answers, collaborate on projects, and drive success.
          </h2>
        </div>
      </div>
      <Spacer space={3} />
      <div className="text-center">
        <hr />
        <h3>Hubs</h3>
        <hr />
      </div>

      <div>
        <Row className="mt-3 justify-content-start">
          {map([projectStore.account, ...discoverHubStore.records], hub => (
            <Col xl={3} lg={4} md={6}>
              <Card className="p-0 pb-3 mr-3" key={hub.id}>
                <CardBody>
                  <Player
                    url={hub.videoUrl}
                    controls="playOnly"
                    muted={false}
                  />
                </CardBody>
                <CardHeader>
                  <CardTitle>
                    <a target="_blank" href={hub.url} rel="noreferrer">
                      <strong>{hub.title}</strong>
                    </a>
                  </CardTitle>
                </CardHeader>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default observer(Discover);
