import {
  action,
  computed,
  extendObservable,
  flow,
  makeObservable,
  observable,
} from 'mobx';

import { includes, isEmpty, map } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import BaseEntity from './base_entity';
import Wallet from './wallet';
import Walletable from '../concerns/walletable';

class User extends BaseEntity {
  /* eslint-disable */
  email;
  accountId;
  accountName;
  @observable status;
  @observable isHubAdmin = false;
  @observable firstName;
  @observable lastName;
  @observable pictureUrl;
  @observable recentProject;
  @observable canPurchaseTokens;
  @observable canCreateProject;
  @observable confirmedProjects;
  @observable strKey;
  @observable fullname;
  @observable isOnboarded = false;
  @observable isSystem = false;
  @observable environment = 'production';
  @observable wallet;
  @observable systemWalletId;
  @observable impersonating = false;
  /* eslint-enable */

  constructor(value, store) {
    super(value, store);

    makeObservable(this);

    extendObservable(this, Walletable);

    this.handleConstruction(value);
  }

  updateUrl = () => `/api/v1/users/${this.id}.json`;

  @computed
  get asSelectOption() {
    return { label: this.fullName, value: this.id };
  }

  @action
  isAccountAdmin() {
    return !isEmpty(this.accountId);
  }

  @action
  isOnboarding() {
    return this.confirmedProjects === 0;
  }

  @action
  logout = () => {
    window.location.href = '/public';
  };

  @action
  handleConstruction(value) {
    const val = { ...value };

    if (val.wallet) {
      this.wallet = new Wallet(
        val.wallet,
        this.store.rootStore.walletEntryStore
      );
      this.wallet.walletableType = 'User';

      delete val.wallet;
    }

    this.initialize(val);
  }

  @action
  addBalance(tokens) {
    this.balance = parseFloat(this.balance) + parseFloat(tokens);
  }

  @flow
  *onboard(params) {
    const response = yield this.client.post(
      `/api/v1/users/onboard.json`,
      params
    );

    if (response.data.success) {
      this.update({ onboarded: response.data.onboarded });
      confirmAlert({
        title: 'Onboarding Successful',
        message: 'You have been successfully onboarded.',
        buttons: [{ label: 'Done' }],
      });
    }
  }

  @flow
  *createHub(params) {
    try {
      const response = yield this.client.post(`/api/v1/helpables.json`, params);

      this.update({ onboarded: response.data.onboarded });
      confirmAlert({
        title: 'Hub created successfully',
        message: 'Your new hub has been been successfully created.',
        buttons: [{ label: 'Done' }],
      });

      return true;
    } catch (e) {
      toast.error(e.response?.data?.message);
      return false;
    }
  }

  @flow
  *verifyOTP(token) {
    try {
      const response = yield this.client.post(
        `/api/v1/users/${this.id}/verify.json`,
        {
          confirmation_token: token,
        }
      );

      toast.success(
        'User has been successfully verified. Please complete the hub creation process.'
      );

      this.store.update({
        currentUser: new User(response.data.user, this.store),
        newUser: null,
      });

      return true;
    } catch (e) {
      toast.error(
        e.response?.data?.message ||
          'Registration Failed. Please check the information you provided and submit again.'
      );
      return false;
    }
  }

  @flow
  *create() {
    try {
      const response = yield this.client.post(`/api/v1/users.json`, {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
      });

      this.update(response.data.user);
      toast.success(
        'User has been successfully created. Please complete the verification process.'
      );

      return true;
    } catch (e) {
      toast.error(
        e.response?.data?.message ||
          'Registration Failed. Please check the information you provided and submit again.'
      );
      return false;
    }
  }
}

export default User;
