import React from 'react';

import Select from 'react-select';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import t from '../../../../t';
import useStore from '../../../../data/store';
import { customStylesAlt } from '../../../../helpers/sidebar_helpers';
import NavIconLink from '../../../layouts/header_nav_icon';

const ProjectSelector = ({ toggleActions, showActions }) => {
  const { projectStore } = useStore();
  const history = useHistory();

  const handleChange = option => {
    projectStore.updateActiveProject(option.value);
    history.push(`/p/${option.value}/`);
  };

  if (projectStore.records.length === 0) {
    return null;
  }

  const isHub = projectStore.activeHelpableType === 'account';

  return (
    <>
      <div className="selector-group">
        <Select
          placeholder={t('placeholder.select_project')}
          options={projectStore.recordsAsSelectOptions}
          value={
            projectStore.activeProject?.isProject
              ? projectStore.activeProjectAsSelectOption
              : null
          }
          onChange={handleChange}
          className="project-selector"
          styles={customStylesAlt}
          menuPlacement="auto"
        />
      </div>
      <div
        className={classnames('project-actions', {
          'show-actions': showActions,
        })}
      >
        {projectStore.isInProjectView && !isHub && (
          <>
            <NavIconLink
              className="tour-home-link "
              icon="home"
              link={`/p/${projectStore.activeProjectId}/asks`}
              title={t(`header.project`)}
              onClick={toggleActions}
            />
            <NavIconLink
              className="tour-activities-link"
              icon="bell"
              link={`/p/${projectStore.activeProjectId}/activities`}
              title={t(`header.notifications`)}
              onClick={toggleActions}
            />
            <NavIconLink
              className="tour-announcements-link"
              icon="bullhorn"
              link={`/p/${projectStore.activeProjectId}/announcements`}
              title={t(`header.announcements`)}
              onClick={toggleActions}
            />
          </>
        )}
        {!projectStore.isInProjectView && !isHub && (
          <NavIconLink
            className="tour-home-link"
            icon="arrow-right"
            link={`/p/${projectStore.activeProjectId}/`}
            onClick={toggleActions}
          />
        )}
      </div>
    </>
  );
};

ProjectSelector.propTypes = {
  toggleActions: PropTypes.func.isRequired,
  showActions: PropTypes.bool.isRequired,
};

export default observer(ProjectSelector);
